import React, { useState } from 'react'
import styled from 'styled-components'
import GlobalWrapper from 'components/global-wrapper'
import Header from 'components/header'
import Footer from 'components/footer'
import { color, fontSize } from 'styles/theme'
import media from 'utils/media-queries'
import ProfileImgSrc from 'img/gabrielschneider.jpg'
import Seo from 'components/seo'
import { v } from 'utils/v-size'

const windowGlobal = typeof window !== 'undefined' && window

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  a {
    display: block;
    color: ${color.coolGrey500};
    :visited {
      color: ${color.coolGrey500};
    }
    :hover {
      color: ${color.coolGrey400};
    }
  }
  ${media.md`
    flex-direction: row;
    margin-bottom: 2.5rem;
    gap: 2rem;
  `}
`

const RightColumn = styled.div`
  ${media.sm`
    h1 {
      font-size: ${fontSize.f8};
    }
  `}
`

const ProfileImage = styled.div`
  background: ${color.coolGrey200};
  background-image: url(${ProfileImgSrc});
  width: 180px;
  height: 180px;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.9;
  ${media.sm`
    width: 120px;
    height: 120px;
  `}
`

const Form = styled.form`
  label {
    font-size: ${fontSize.f6};
    margin-bottom: 1rem;
    margin-top: 2rem;
    display: block;
  }
  input,
  textarea {
    padding: 1rem;
    box-sizing: border-box;
  }
  margin-top: 2vw;
`

const Email = styled.input`
  width: 100%;
  border-radius: 2px;
  border: 2px solid ${color.coolGrey300};
  border-style: normal;
  :focus {
    outline: 0;
    border: 2px solid ${color.blue500};
  }
`

const Message = styled.textarea`
  width: 100%;
  height: 250px;
  border-radius: 2px;
  border: 2px solid ${color.coolGrey300};
  border-style: normal;
  :focus {
    outline: 0;
    border: 2px solid ${color.blue500};
  }
`

const SubmitButton = styled.button`
  margin-top: 2rem;
  padding: 1rem 3rem;
  width: 100%;
  border-radius: 2px;
  -webkit-appearance: none;
  background: ${color.coolGrey200};
  color: black;
  border: 2px solid ${color.coolGrey300};
  border-style: normal;
  :hover {
    background: ${color.coolGrey100};
    border: 2px solid ${color.coolGrey300};
  }
  :focus {
    outline: 0;
    border: 2px solid ${color.blue500};
  }
  :active {
    background: ${color.coolGrey200};
    border: 2px solid ${color.coolGrey300};
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Container = styled.div`
  padding: calc(32px + 5vw) 0;
  width: 100%;
  max-width: 900px;
  margin: 0 ${v(20, 140)};
  box-sizing: border-box;
  min-height: calc(100vh - 162px);
  display: grid;
  grid-template-columns: 180px auto;
  grid-column-gap: 5vw;
  grid-row-gap: 2rem;
  ${media.md`
    display: block;
  `}
  ${media.sm`
    min-height: calc(100vh - 300px);
  `}
`

const SuccessMessage = styled.div`
  background: ${color.green50};
  padding: 1rem;
  border: 2px solid ${color.green300};
  color: ${color.green800};
  margin-top: 2rem;
`

const PendingMessage = styled.div`
  margin-top: 2rem;
`

const ErrorMessage = styled.div`
  background: ${color.red50};
  padding: 1rem;
  border: 2px solid ${color.red300};
  color: ${color.red800};
  margin-top: 2rem;
  a {
    color: ${color.red800};
    text-decoration: underline;
    :visited {
      color: ${color.red800};
    }
  }
`

const Contact = () => {
  const [status, setStatus] = useState()
  const submitForm = ev => {
    ev.preventDefault()
    setStatus('PENDING')
    const form = ev.target
    const data = new windowGlobal.FormData(form)
    const xhr = new windowGlobal.XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== windowGlobal.XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setStatus('SUCCESS')
      } else {
        setStatus('ERROR')
      }
    }
    xhr.send(data)
  }
  return (
    <GlobalWrapper>
      <Seo title="Contact" />
      <Header />
      <Wrapper>
        <Container>
          <LeftColumn>
            <ProfileImage />
            <div>
              Gabriel Schneider
              <br />
              <a href="https://linkedin.com/in/gabrielvschneider/">
                in/gabrielvschneider
              </a>
              <a href="https://twitter.com/gabdorf">@gabdorf</a>
            </div>
          </LeftColumn>
          <RightColumn>
            <h1>Let's work together.</h1>
            <Form
              onSubmit={submitForm}
              action="https://formspree.io/f/mnqrzzlz"
              method="POST"
            >
              <label htmlFor="mail">Email</label>
              <Email
                type="email"
                id="mail"
                placeholder="Email"
                name="_replyto"
                required
              />
              <label htmlFor="message">Message</label>
              <Message
                name="message"
                id="message"
                placeholder="Your message..."
                required
              />
              <br />
              {status === 'SUCCESS' ? (
                <SuccessMessage>
                  Message sent. Thank you for your inquiry!
                </SuccessMessage>
              ) : status === 'PENDING' ? (
                <PendingMessage>Sending...</PendingMessage>
              ) : (
                <SubmitButton type="submit">Send</SubmitButton>
              )}
              {status === 'ERROR' && (
                <ErrorMessage>
                  Error. Please send an email to info[ät]gabrielschneider.de
                  instead.
                </ErrorMessage>
              )}
            </Form>
          </RightColumn>
        </Container>
      </Wrapper>
      <Footer />
    </GlobalWrapper>
  )
}

export default Contact
