import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { v } from '../utils/v-size'

const TitleBar = styled.div`
  display: flex;
  border-bottom: 2px solid black;
  height: 80px;
  width: 100%;
`

const Container = styled.div`
  margin-left: ${v(20, 140)};
  margin-right: ${v(20, 140)};
  width: calc(100% - ${v(32, 280)});
  justify-content: space-between;
  align-items: center;
  display: flex;
`

const ArrowHead = styled.div`
  width: 47px;
  height: 26px;
  min-height: 47px;
  min-width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.sm`
    display: none;
  `}
`

const BackLink = styled(Link)`
  padding: 0;
  display: flex;
  align-items: center;
  background: none;
  font-style: normal;
  cursor: pointer;
  svg {
    fill: black;
    transition: transform 300ms ease;
    transform: translateX(0);
  }
  :hover {
    svg {
      transform: translateX(-10px);
    }
  }
`

const TextLinks = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`

const TwitterLink = styled.a`
  border: 2px solid black;
  border-radius: 20px;
  padding: 2px 12px;
  :hover {
    background: black;
    color: white;
  }
`

const MobileNameLink = styled(Link)`
  display: none;
  ${media.sm`
  display: flex;
  align-items: center;
`}
`

const DesktopNameLink = styled(Link)`
  display: block;
  ${media.sm`
  display: none;
`}
`

const SmallArrow = styled.span`
  margin-right: 12px;
  padding-top: 1px;
  font-size: 24px;
`

const ArticleHeader = props => {
  return (
    <Fragment>
      <TitleBar>
        <Container>
          <BackLink to="/">
            <ArrowHead>
              <svg
                fill="none"
                height="26"
                viewBox="0 0 47 26"
                width="47"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="m5.01771 14.2501 9.86609 9.8661-1.7677 1.7678-12.883922-12.8839 12.883922-12.883889 1.7677 1.767769-9.86609 9.86612h41.98229v2.5z"
                  fill="#000"
                  fillRule="evenodd"
                />
              </svg>
            </ArrowHead>
            <MobileNameLink>
              <SmallArrow>←</SmallArrow>Gabriel Schneider
            </MobileNameLink>
          </BackLink>
          <TextLinks>
            <DesktopNameLink to="/">Gabriel Schneider</DesktopNameLink>
            <TwitterLink
              href="https://twitter.com/gabdorf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter ↗
            </TwitterLink>
          </TextLinks>
        </Container>
      </TitleBar>
    </Fragment>
  )
}

export default ArticleHeader
